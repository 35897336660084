import {
  HomePageCompanyLogo,
  HomePageStarredEntities, HomePageToolkit
} from '@backstage/plugin-home';

import { Content, Page } from '@backstage/core-components';
import {
  HomePageSearchBar
} from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { AnnouncementsCard, NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';

import {
  AWSLogo,
  GithubLogo, GrafanaLogo, SigNozLogo, HomePageLoggiLogo, OpenSearchLogo, OpsGenieLogo, SentryLogo, UnleashLogo, VaultLogo, AirflowLogo,
  KialiLogo, RundeckLogo, SonarcloudLogo, DockerhubLogo, ArgoCDLogo, OpenMetadataLogo
} from '../../assets/HomePageLogos';

import { ImportantLinksCard } from './homePageComponents/ImportantLinksCard';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: '8px 8px',
    borderRadius: '10px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container>
            <Grid item md={12}>
              <NewAnnouncementBanner />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<HomePageLoggiLogo />}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageToolkit
                tools={[
                  {
                    url: 'https://github.com/loggi',
                    label: 'Github',
                    icon: <GithubLogo />,
                  },
                  {
                    url: 'https://app.unleash-hosted.com',
                    label: 'Unleash',
                    icon: <UnleashLogo />,
                  },
                  {
                    url: 'https://argo.platform.loggi.com/',
                    label: 'ArgoCD',
                    icon: <ArgoCDLogo />,
                  },
                  {
                    url: 'https://hub.docker.com/?namespace=loggi',
                    label: 'Dockerhub',
                    icon: <DockerhubLogo />,
                  },
                  {
                    url: 'https://sentry.io/loggi',
                    label: 'Sentry',
                    icon: <SentryLogo />,
                  },
                  {
                    url: 'https://grafana.loggi.com',
                    label: 'Grafana',
                    icon: <GrafanaLogo />,
                  },
                  {
                    url: 'https://telemetry.loggi.com',
                    label: 'SigNoz',
                    icon: <SigNozLogo />,
                  },
                  {
                    url: 'https://sonarcloud.io/organizations/loggi/projects',
                    label: 'SonarCloud',
                    icon: <SonarcloudLogo />,
                  },
                  {
                    url: 'https://kibana.platform.loggi.com/',
                    label: 'Logs',
                    icon: <OpenSearchLogo />,
                  },
                  {
                    url: 'https://kibana.platform.stg.loggi.com/',
                    label: 'Logs Staging',
                    icon: <OpenSearchLogo />,
                  },
                  {
                    url: 'https://loggi-identity.signin.aws.amazon.com/console',
                    label: 'AWS',
                    icon: <AWSLogo />,
                  },
                  {
                    url: 'https://loggi.app.opsgenie.com/',
                    label: 'OpsGenie',
                    icon: <OpsGenieLogo />,
                  },
                  {
                    url: 'https://vaultin.loggi.com/',
                    label: 'Vault (Prod)',
                    icon: <VaultLogo />,
                  },
                  {
                    url: 'https://vaultin.stg.loggi.com',
                    label: 'Vault (Stg)',
                    icon: <VaultLogo />,
                  },
                  {
                    url: 'https://open-metadata.data.loggi.com/',
                    label: 'Open Metadata',
                    icon: <OpenMetadataLogo />,
                  },
                  {
                    url: 'https://airflow.data.loggi.com/',
                    label: 'Airflow',
                    icon: <AirflowLogo />,
                  },
                  {
                    url: 'https://airflow.data.stg.loggi.com/',
                    label: 'Airflow Staging',
                    icon: <AirflowLogo />,
                  },
                  {
                    url: 'https://rundeck.loggi.com/',
                    label: 'Rundeck',
                    icon: <RundeckLogo />,
                  },
                  {
                    url: 'https://kiali-prod-eks-04.loggi.com/',
                    label: 'Kiali EKS production (prod-eks-04)',
                    icon: <KialiLogo />,
                  },
                  {
                    url: 'https://kiali-eks.loggi.com/',
                    label: 'Kiali EKS production',
                    icon: <KialiLogo />,
                  },
                  {
                    url: 'https://kiali-eks.stg.loggi.com/',
                    label: 'Kiali EKS staging',
                    icon: <KialiLogo />,
                  },
                  {
                    url: 'https://kiali-tools.loggi.com/',
                    label: 'Kiali EKS Tools cluster',
                    icon: <KialiLogo />,
                  },
                ]}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <AnnouncementsCard max={3} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <ImportantLinksCard />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
